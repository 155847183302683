
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsEan',
    components: {},
})
export default class DetailsEan extends Vue {
    @Prop({ type: String, required: true }) private eanText: string;

    get itemnumber(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) return ProductDetailsModule.SELECTED_VARIANT?.ean;
        else return ProductDetailsModule.PRODUCT?.ean ?? '';
    }

    get hidden(): boolean {
        return (
            ProductDetailsModule.VARIANTS?.length > 0 && CbxVariablesModule.VARIABLES.variantDisplayOptions.productlist
        );
    }
}
